// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-corporate-compliance-committee-tsx": () => import("./../../../src/layouts/corporateComplianceCommittee.tsx" /* webpackChunkName: "component---src-layouts-corporate-compliance-committee-tsx" */),
  "component---src-layouts-corporate-financial-technology-tsx": () => import("./../../../src/layouts/corporateFinancialTechnology.tsx" /* webpackChunkName: "component---src-layouts-corporate-financial-technology-tsx" */),
  "component---src-layouts-corporate-founders-tsx": () => import("./../../../src/layouts/corporateFounders.tsx" /* webpackChunkName: "component---src-layouts-corporate-founders-tsx" */),
  "component---src-layouts-corporate-home-tsx": () => import("./../../../src/layouts/corporateHome.tsx" /* webpackChunkName: "component---src-layouts-corporate-home-tsx" */),
  "component---src-layouts-general-tsx": () => import("./../../../src/layouts/general.tsx" /* webpackChunkName: "component---src-layouts-general-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

